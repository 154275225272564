import React from 'react';
export class MaintenanceContainer extends React.Component {
  render() {     
    return (           
        <div className="row alert" >
          <div className="col-lg-12 covid" style={{ marginTop: "-30px" }}>
            <h4><i className="glyphicon glyphicon-alert"></i>  <span class="covid-warn">Maintenance on Sunday, February 9 from 10 am to 12 noon CST</span></h4>
            <ul>
              <li>We will have scheduled maintenance on Sunday, February 9 from 10 am to 12 noon CST.</li>
              <li>During this window there may be parts of the application that are delayed or inaccessible.  Thank you for your patience during this time.</li>             
            </ul>
          </div>
        </div>
    );
    }
}
export default (MaintenanceContainer);